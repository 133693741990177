/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {

    if ('ontouchstart' in window) {
        jQuery(document.body).addClass("touch");
    }

});
