jQuery(document).ready(function($){

    $('[data-count-parent]').each(function() {
        new Waypoint.Inview({
            element: this,
            enter: function (direction) {
                $(this.element).find('[data-count]').each(function() {
                    var $this = $(this),
                        count_max = $this.data("count");

                    $({ count_num: $this.text() }).animate(
                        {
                            count_num: count_max
                        },
                        {
                            duration: 8000,
                            easing: "linear",
                            step: function() {
                                $this.text(Math.floor(this.count_num));
                            },
                            complete: function() {
                                $this.text(this.count_num);
                            }
                        }
                    );
                });
            }
        })
    });

});
